import React, { useEffect } from "react";

import { setDescription, setTitle } from "../../utils";
import MailEnquiry from "../../components/mail/Enquiry";
import Styles from "./contact.module.css";
import EventsImage1 from '../../assets/images/mm-graffiti-hero.jpg';
import { RevealSlide } from "../../components/animations/revealSlide/revealSlide";
import { Reveal } from "../../components/animations/reveal/reveal";

export const Contact = () => {

    useEffect(() => {
        setTitle("Marketing Manchester | Contact");
        setDescription("Get in touch with Marketing Manchester to find out how you can be involved in our national & international marketing activity & promotional campaigns in the visitor economy & key sectors");
    }, []);

    return (
        <>
            <div className={Styles.contactPageContainer}>
                <div className={`${Styles.contactContainerTop}`}>
                    <Reveal>
                        <div style={{ backgroundImage: `url(${EventsImage1})` }} className={`${Styles.contactHeroImage}`}></div>
                    </Reveal>
                </div>

                <div className={`${Styles.contactContentContainer}`} >

                    <article className={`${Styles.contactContentleft}`}>

                        <div className={`${Styles.contactDetailsContainer}`}>
                            <Reveal>
                                <h2>Get in touch today</h2>
                            </Reveal>
                            <Reveal>
                                <p className={`${Styles.contactContentMargin}`}>
                                    Lee House<br></br>
                                    90 Great Bridgewater Street<br></br>
                                    Manchester<br></br>
                                    M1 5JW<br></br>
                                </p>
                            </Reveal>
                            <Reveal>
                                <p className={`${Styles.contactContentMargin} ${Styles.wordWrap} ${Styles.medium}`}><span className="gcicon-mail"></span> General enquiry:<br></br> <span><a href="mailto:info@marketingmanchester.com">info@marketingmanchester.com</a></span></p>
                            </Reveal>
                            <Reveal>
                                <p className={`${Styles.contactContentMargin} ${Styles.wordWrap} ${Styles.medium}`}><span className="gcicon-mail"></span> Press enquiry:<br></br> <span><a href="mailto:press@marketingmanchester.com">press@marketingmanchester.com</a></span></p>
                            </Reveal>
                            <Reveal>
                                <p className={`${Styles.contactContentMargin} ${Styles.wordWrap} ${Styles.medium}`}><span className="gcicon-mail"></span> Tourism intelligence:<br></br> <span><a href="mailto:research@marketingmanchester.com">research@marketingmanchester.com</a></span></p>
                            </Reveal>
                            <Reveal>
                                <p className={`${Styles.contactContentMargin} ${Styles.wordWrap} ${Styles.medium}`}><span className="gcicon-image"></span> Resources:<br></br> <span><a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">Access our image library</a></span></p>
                            </Reveal>

                        </div>

                    </article>
                    <aside className={`${Styles.contactContentRight}`}>
                        <div className={`${Styles.formContainer}`}>
                            <Reveal>
                                <MailEnquiry title="If you have a general query or want to work with us, please fill out the form below." email="info@marketingmanchester.com" template={1} />
                            </Reveal>
                        </div>
                    </aside>
                </div>
            </div>
            



        </>
    );
};