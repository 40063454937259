import React from "react";
import { Navmenu } from "../navmenu/navmenu";
import { Footer } from "../footer/footer";


export const Layout = (props) => {
    return (
        <div>
            <Navmenu />
            {props.children}
            <Footer />
        </div>
    );
};