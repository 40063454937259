import React from "react";

import Styles from "./annualReview.module.css";

export const AnnualReview = () => {

    return (
        <div className={Styles.reviewContainer}>
            <div>
                <h1>Our Annual Review</h1>
                <h3>
                    2023-24 was one our strongest yet, packed with activities across our key strands as well as many cross-sector projects delivered with and on behalf of key stakeholders.
                </h3>
                <p>
                    The team built on the strong foundations put in place during 2022-23, delivering above and beyond for the visitor economy, meeting, conference and events sector, place promotion partnership, and our crucial frontier sectors. We amplified our marketing campaigns by going back into more markets than ever before, leveraged our relationships with the media to deliver rich and meaningful storytelling, and pushed our digital channels further and harder.
                </p>
                <p>
                    With the newly established of the Manchester Accommodation BID and our contract to deliver marketing and business events activities on behalf of accommodation levy payers, we were able to expand our reach, run new seasonal campaigns, and win bids for more events.
                </p>
                <p>
                    And amongst everything, we proved the value of our work, enriched our existing partnerships whilst building important new strategic partnerships to equip us with the resources to tackle the next crucial next phase Greater Manchester's development.
                </p>
                <p>
                    In this film, Victoria Braddock, Managing Director of Marketing Manchester summarises the work undertaken and key achievements that made 2023-24 one of our most successful to date.
                </p>
            </div>
            <div>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/2udCOB3d8Pw?si=0Dtdc5VEQGX6bB3P" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </div>
    )

}

