import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { populateNewsData} from "../../utils";
import { useIndividual } from "../../components/useIndividual/useIndivdual";
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import { ColourSwitch, ColourBGSwitch } from "../../components/colourswitch/colourswitch";
import { getParsedDate, getParsedDateFull } from "../../helpers";
import { Social } from "../../components/social/social";

import Styles from "./individualNews.module.css";
import { Reveal } from "../../components/animations/reveal/reveal";
import { SlideLeft } from "../../components/animations/slideLeft/slideLeft";

export const IndividualNews = () => {
    const { newsId } = useParams();
    const { data, isLoading, error } = useIndividual(newsId, "News");
    const [newsItems, setNewsItems] = useState([]);

    const fetchSideNews = async () => {
        try {
            let data = await populateNewsData();
            let ln = data.newsItems.filter(item => item.urlSegment !== newsId && item.display === true)
            let latestNews = ln.slice(0, 3);
            setNewsItems(latestNews);
        } catch (error) {          
        } 
    };

    useEffect(() => {
        fetchSideNews();
    }, [newsId]);

    const renderHTML = (rawHTML) => {
        let output = rawHTML;
        let i = 0, x = 0;
        let _url = process.env.REACT_APP_MM_UMBRACO_API;
        const regex = /<img src="/g;
        let matches = output.matchAll(regex);
        for (const match of matches) {
            if (i > 0) {
                x = i * _url.length;
                output = [output.slice(0, match.index + match[0].length + x), _url, output.slice(match.index + match[0].length + x)].join('');
            } else {
                output = [output.slice(0, match.index + match[0].length), _url, output.slice(match.index + match[0].length)].join('');
            }
            i+=1;
        }
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: output } });
    };

    if (error) {
        return <Error error={error} id="individual-news-error" />;
    }

    return (
        <div>
            {
                isLoading ? <Loader data-testid="loader" page="Event item" /> :
                    <div className={Styles.newsContainer}>
                        <header className={`${Styles.newsContainerTop}`}>
                            <div>
                                <p className={`${Styles.newsContentMargin}`}>
                                    <Link to="/News" state={{ from: "indivdualNews" }} className={Styles.goBackDesktop}>
                                        <i className="gcicon-left-open"></i>Back to news
                                    </Link>
                                </p>
                            </div>
                            <div> <p className={`${Styles.newsContentMargin}`}><span className={`${Styles.sectorMarker} ${ColourSwitch(data.category)}`}>|</span> <span className={`${Styles.sectorName}`}>{data.category}</span></p></div>
                            <Reveal>
                                <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${data.bannerImage})` }} className={`${Styles.newsHeroImage}`}></div>
                            </Reveal>
                        </header>

                        <div className={`${Styles.newsItemContentContainer}`}>
                            <article className={`${Styles.newsItemContentleft}`}>

                                <div className={`${Styles.newsDetailsContainer}`}>
                                    <Reveal>
                                        <p className={`${Styles.newsContentMargin}`}><span className={`${Styles.normal}`}>Date published: {getParsedDateFull(data.articleDate)}</span></p>
                                    </Reveal>
                                </div>

                                <div className={`${Styles.newsContentContainer}`}>
                                    <Reveal>
                                        <h1>{data.headLine}</h1>
                                    </Reveal>
                                    <Reveal>
                                        {renderHTML(data.infoHtml)}
                                    </Reveal>
                                </div>

                                <div className={Styles.scollMarker} onClick={(ev) => { window.scrollTo(0, 0) }}>Scroll Top</div>
                            </article>
                            <aside className={`${Styles.newsAsideContainer}`}>
                                <div className={Styles.socialContainer}>
                                    <SlideLeft>
                                        <Social socialtype="news" title={data.headLine} />
                                    </SlideLeft>
                                </div>
                                {
                                    newsItems.map((item, idx) => (
                                        <SlideLeft>
                                        <div className={`${Styles.newsCardStyle}`} key={item.id }>
                                            <Link to={`/news/${item.urlSegment}`}>
                                                <div className={`${Styles.cardOverlayContainer}`}>
                                                    <div className={`${Styles.cardOverlay} ${ColourBGSwitch(item.category)}`}></div>
                                                    <div className={`${Styles.newsContent}`} >
                                                        <div>
                                                            <p><span className={`${Styles.sectorMarker} ${ColourSwitch(item.category)}`}>|</span> <span className={`${Styles.sectorName}`}>{ item.category }</span></p>
                                                            <p className={`${Styles.newsContentMargin}`}><i className="gcicon-calendar"> </i><span className="date">{getParsedDate(item.articleDate)}</span></p>

                                                            <h3>{item.headLine}</h3>
                                                            <p style={{margin:'0'} }>Details <span style={{ fontSize: `12px` }} className="gcicon-right-open"> </span></p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        </SlideLeft>
                                    ))
                                }
                                
                            </aside>
                        </div>
                    </div>
            }
        </div>
    );
};
