import { useNavigate } from "react-router-dom";
import styles from "./PageNotFound.module.css";

export const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <h1>404</h1>
                <h2>Uh, looks like the page you're looking for does not exist</h2>
                <h4 onClick={() => navigate(-1)} className={styles.goBack}>
                    Click here to go back
                </h4>
            </div>
        </div>
    );
};
