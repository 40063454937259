
export const populateWeatherData = async () => {
    try {
        const url = process.env.REACT_APP_MM_API_BASEURL + "weather";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const setTitle = title => {
    const el = document.querySelector('title');
    el.innerText = `${title}`;
};

export const setDescription = desc => {
    const el = document.querySelector("meta[name='description']");
    el.setAttribute('content', desc)
}

export const populateNewsData = async () => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/News";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const getNewsDetail = async (id) => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/News/" + id;
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const saveNewsSelectedToLocalSession = (selected) => {
    sessionStorage.setItem("news-selected", JSON.stringify(selected));
};

export const getNewsSelectedFromLocalSession = () => {
    const selected = sessionStorage.getItem("news-selected");
    const parsedInputs = JSON.parse(selected);

    if (selected) {
        return parsedInputs;
    }
};

export const populateEventsData = async () => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/Events";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const getEventsDetail = async (id) => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/Events/" + id;
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const saveEventsSelectedToLocalSession = (selected) => {
    sessionStorage.setItem("events-selected", JSON.stringify(selected));
};

export const getEventsSelectedFromLocalSession = () => {
    const selected = sessionStorage.getItem("events-selected");
    const parsedInputs = JSON.parse(selected);

    if (selected) {
        return parsedInputs;
    }
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

export const populateWhoWeAreData = async () => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/WhoWeAre/";
        let response = await fetch(url, {  
            method: "GET",
            headers: [["Content-Type", "application/json"]],
            mode: "cors",
            cache: "default",
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const saveWhoweAreToLocalSession = (whoweare) => {
    localStorage.setItem("whoweare-results", JSON.stringify(whoweare));
};

export const getWhoweAreFromLocalSession = () => {
    const whoweare = localStorage.getItem("whoweare-results");
    const parsedWhoweare = JSON.parse(whoweare);

    if (whoweare) {
        return parsedWhoweare;
    }
};

export const populateMarketingData = async () => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/Marketing/";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const saveMarketingDataLocalSession = (marketing) => {
    sessionStorage.setItem("marketing-results", JSON.stringify(marketing));
};

export const getMarketingDataFromLocalSession = () => {
    const marketing = sessionStorage.getItem("marketing-results");
    const parsedMarketing = JSON.parse(marketing);

    if (marketing) {
        return parsedMarketing;
    }
};

export const populateWhatWeDoData = async () => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/WhatWeDo/";
        let response = await fetch(url, { 
            method: "GET",
            headers: [["Content-Type", "application/json"]],
            mode: "cors",
            cache: "default",
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const saveWhatWeDoLocalSession = (whatwedo) => {
    localStorage.setItem("whatwedo-results", JSON.stringify(whatwedo));
};

export const getWhatWeDoFromLocalSession = () => {
    const whatwedo = localStorage.getItem("whatwedo-results");
    const parsedWhatwedo = JSON.parse(whatwedo);

    if (whatwedo) {
        return parsedWhatwedo;
    }
};

export const populateWorkWithUsData = async () => {
    try {
        const url = process.env.REACT_APP_MM_UMBRACO_API + "MMUmbraco/WorkWithUs/";
        let response = await fetch(url, { 
            method: "GET",
            headers: [["Content-Type", "application/json"]],
            mode: "cors",
            cache: "default",
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const saveWorkWithUsLocalSession = (workwithus) => {
    sessionStorage.setItem("workwithus-results", JSON.stringify(workwithus));
};

export const getWorkWithUsFromLocalSession = () => {
    const workwithus = sessionStorage.getItem("workwithus-results");
    const parsedWorkwithUs = JSON.parse(workwithus);

    if (workwithus) {
        return parsedWorkwithUs;
    }
};

export const postEnquiryEmailData = async (emailObject) => {
    let sent = true;
    try {
        await fetch(process.env.REACT_APP_MM_API_BASEURL + "enquiries", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(emailObject),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            sent = data;
        }).catch((error) => {
            sent = false;
        });

        return sent;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

window.addEventListener("pagehide", (event) => {
    /*event.preventDefault()*/
    clearLocalStorage();
    /*return event.returnValue = '';*/
});



