import React from "react";
import { Link } from "react-router-dom";
import { ColourSwitch, ColourBGSwitch } from "../../components/colourswitch/colourswitch";
import { getParsedDate } from "../../helpers";
import NewsCardStyles from "./newsCard.module.css";
import Btn from "../../components/Button/Button";

export const NewsCard = (props) => {

    return (

        <>

            <div className={`${NewsCardStyles.cardOverlayContainer} ${props.cardBgColour}`}>
                <div className={`${NewsCardStyles.newsCardStyle}`}>
                    
                    <Link
                        to={`${props.url}`}
                    >
                            <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${props.bannerImage}` }} className={`${NewsCardStyles.newsCardImage}`}></div>
                    </Link>

                    <div className={`${NewsCardStyles.cardOverlay} ${ColourBGSwitch(props.category)}`}></div>
                
                    <div className={`${NewsCardStyles.newsContent}`} >
                        <p className={`${NewsCardStyles.newsContentMargin}`}><span className={`${NewsCardStyles.sectorMarker} ${ColourSwitch(props.category)}`}>|</span> <span className={NewsCardStyles.sectorName}>{props.category}</span></p>
                        <p className={`${NewsCardStyles.newsContentMargin}`}><span className={NewsCardStyles.date}>{getParsedDate(props.articleDate)}</span></p>
                        <Link
                            to={`${props.url}`}
                        >
                            <h3 className={`${NewsCardStyles.newsContentMargin}`}>{props.headLine}</h3>
                        </Link>
                        <p className={`${NewsCardStyles.newsContentMargin}`}>{props.summary}</p>
                        
                    </div>
                  
                </div>
                <Link
                    className={`${NewsCardStyles.newsCardLink}`}
                    to={`${props.url}`}
                >
                    <div className={`${NewsCardStyles.buttonContainer}`}>
                        <Btn text={"Read more"} color="Black" />
                    </div>
                </Link>
            </div>
   
        </>
    );
};