import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import {
    setDescription,
    setTitle,
    populateWhoWeAreData,
    saveWhoweAreToLocalSession,
    getWhoweAreFromLocalSession
} from "../../utils";

import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import Btn from "../../components/Button/Button";
import { Hero } from "../../components/hero/heroParallax"
import { TeamCard } from "../../components/TeamCard/teamCard";

import Styles from "./whoWeAre.module.css";
import "./whoWeAre.css";
import { renderBullets, renderButton } from "../../helpers";
import heroImage from "../../assets/images/who-we-are/the-university-of-manchester-hero.jpg";
import image5 from "../../assets/images/who-we-are/work-with-us.jpg";

import profile1 from "../../assets/images/profiles/victoria-braddock-profile.jpg";
import profile2 from "../../assets/images/profiles/nick-brooks-sykes-profile.jpg";
import profile3 from "../../assets/images/profiles/louise-latham-2.jpg";
import profile4 from "../../assets/images/profiles/stephanie-newton.jpg";
import profile5 from "../../assets/images/profiles/richard-sherwood.jpg";
import profile6 from "../../assets/images/profiles/william-dolby.jpg";
import profile7 from "../../assets/images/profiles/ryan-maley.jpg";
import { Reveal } from "../../components/animations/reveal/reveal";
import { FadeIn } from "../../components/animations/fadeIn/fadeIn";
import { SlideRight } from "../../components/animations/slideRight/slideRight";
/*import { SlideLeft } from "../../components/animations/slideLeft/slideLeft";*/


export const WhoWeAre = () => {
    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const container = {
        initial: {
            opacity: 0,
            y: 75,
        },
        animate:{
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.25
            }
        },
    };

    const item = {
        initial: {
            opacity: 0,
            y: 75,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        },
    }

    useEffect(() => {
        setTitle("Marketing Manchester | Who we are");
        setDescription("Marketing Manchester is the city - region's official destination marketing organisation, incorporating the tourist board & the Manchester Convention Bureau & Sports Bidding Unit");
    }, []);

    const fetchWho = async () => {
        try {
            setIsLoading(true);
            let data = getWhoweAreFromLocalSession();
            if (!data) {
                data = await populateWhoWeAreData();
                saveWhoweAreToLocalSession(data);
            }
            setDisplayItems(data);

        } catch (error) {
            setError({ message: "Uh oh, looks like something went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchWho();
    }, []);

    return (
        <>
            <Hero
                image={heroImage}
                height="75vh"
                title="Who we are"
            />
            <div className={`${Styles.pageContainer}`}>
                {
                    isLoading ? <Loader page="Who we are" /> :
                        error ? <Error error={error} id="dashboard-error" page="Whoweare" /> :
                        displayItems.slice(0, 10).map((who, idx) => (

                            <div className={`sectionContainer${idx + 1} ${idx === 1 ? Styles.ltBlueBg : ''} ${idx === 2 ? Styles.creamBg : ''} ${idx === 3 ? Styles.ltRedBg : ''} ${idx === 4 ? '' : ''} ${idx === 5 ? Styles.ecoGreenBg : ''} ${idx === 6 ? Styles.creamBg : ''} ${idx === 7 ? Styles.ltGreyBg : ''} ${idx === 8 ? Styles.ltCameoGreenBg : ''} ${idx === 9 ? Styles.creamBg : ''}`} key={who.id}>
                                {idx % 2 === 0 || idx === 0 ?
                                    <div className={`Section${idx + 1}`} >
                                        <div>
                                            <Reveal>
                                                <h1>{who.title}</h1>
                                            </Reveal>
                                            <Reveal>
                                                <h3>{who.mainText}</h3>
                                            </Reveal>
                                            <Reveal>
                                                {renderBullets(who)}
                                            </Reveal>
                                            <Reveal>
                                                <p>{who.subText}</p>
                                            </Reveal>
                                            <Reveal>
                                                {renderButton(who)}
                                            </Reveal>
                                        </div>
                                        <FadeIn>
                                            <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${who.bannerImage})` }} className={`image${idx + 1}`}></div>
                                        </FadeIn>
                                    </div>
                                    :
                                    <div className={`Section${idx + 1}`} >
                                        <FadeIn>
                                            <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${who.bannerImage})` }} className={`image${idx + 1}`}></div>
                                        </FadeIn>
                                        <div>
                                            <Reveal>
                                                <h1>{who.title}</h1>
                                            </Reveal>
                                            <Reveal>
                                                <h3>{who.mainText}</h3>
                                            </Reveal>
                                            <Reveal>
                                                {renderBullets(who)}
                                            </Reveal>
                                            <Reveal>
                                                <p>{who.subText}</p>
                                            </Reveal>
                                            <Reveal>
                                                {renderButton(who)}
                                            </Reveal>
                                        </div>
                                       
                                    </div>
                                }
                            </div>
                        ))
                }

            </div>

            <section>
                <Reveal>
                    <h1>Our senior management team</h1>
                </Reveal>
                <motion.div
                    variants={container}
                    initial="initial"
                    whileInView="animate"
                    viewport={{
                        once: true,
                    }}
                 
                    className={`${Styles.teamContainer}`} >

                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="Victoria Braddock"
                            position="Managing Director"
                            linkedin="https://www.linkedin.com/in/victoria-braddock-a2162446/"
                            image={profile1}
                            colour="tealBg"
                        />
                    </motion.div>
                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="Nick Brooks-Sykes"
                            position="Director of Tourism"
                            linkedin="https://www.linkedin.com/in/nick-brooks-sykes-7400654/"
                            image={profile2}
                            colour="yellowBg"
                        />
                    </motion.div>
                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="Louise Latham"
                            position="Director of Communications"
                            linkedin="https://www.linkedin.com/in/louisemlatham/"
                            image={profile3}
                            colour="blueBg"
                        />
                    </motion.div>
                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="Stephanie Newton"
                            position="Head of Business Tourism"
                            linkedin="https://www.linkedin.com/in/stephanie-newton-6b3b0134/"
                            image={profile4}
                            colour="redBg"
                        />
                    </motion.div>
                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="Richard Sherwood "
                            position="Head of Commercial Partnerships"
                            linkedin="https://www.linkedin.com/in/richard-gf-sherwood/"
                            image={profile5}
                            colour="ltPurpleBg"
                        />
                    </motion.div>
                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="William Dolby"
                            position="Head of Sector Marketing"
                            linkedin="https://www.linkedin.com/in/william-dolby-81446927/"
                            image={profile6}
                            colour="cameoGreenBg"
                        />
                    </motion.div>
                    <motion.div
                        variants={item}
                    >
                        <TeamCard
                            name="Ryan Maley"
                            position="Head of Digital"
                            linkedin="https://www.linkedin.com/in/ryanmaley1/"
                            image={profile7}
                            colour="orangeBg"
                        />
                    </motion.div>

                </motion.div>
            </section>

            <div className={`${Styles.sectionContainer5} ${Styles.ltGreyBg}`}>

                <div className={`${Styles.workWithUs_Section5}`} >

                    <div>
                        <Reveal>
                            <h1>Work with us</h1>
                        </Reveal>
                        <Reveal>
                        <h3>
                            We deliver local, national, and international activity for a network of public and private partners.
                            </h3>
                        </Reveal>
                        <Reveal>
                        <p>
                            Whether your business or organisation functions within the visitor economy, inward investment, the property sector, or somewhere in between, we have the right network and opportunities for you.
                            </p>
                        </Reveal>
                        <Reveal>
                            <Link as={Link} to="/workWithUs" href="/workWithUs"><Btn text={"Find out more"} color="Black" /></Link>
                        </Reveal>
                    </div>
                    <SlideRight>
                        <div style={{ backgroundImage: `url(${image5})` }} className={`${Styles.image5}`}></div>
                    </SlideRight>
                </div>

            </div>
        </>
    );
};