import Button from "react-bootstrap/Button";
import "./Button.css";

const Btn = (props) => {
  return (
    <Button
      type={props.type}
      onClick={props.onClick}
      className={props.color}
      disabled={props.disabled}
      name={props.name}
      style={{ width: props.width, height: props.height, }}
    >
      {props.text} {props.arrow ? <span className="gcicon-right"></span> : null}
    </Button>
  );
};

export default Btn;
