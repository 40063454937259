
import styles from "./Terms.module.css"

export const Terms = () => {
    return (
        <>
            
            <div className={styles.termsContainer}>
                <h1>Terms & Conditions</h1>
                <h4 className={styles.termsTitle}>Terms of use</h4>
                <p>
                    Access to and use of this site (www.marketingmanchester.com) is provided by Marketing Manchester, the agency charged with promoting Greater Manchester on a national and international scale subject to the following terms:
                </p>

                <p>
                    By using this site you agree to be legally bound by these terms, which shall take effect immediately on your first use of this site. If you do not agree to be legally bound by all the following terms please do not access and/or use this site.
                </p>
                <p>
                    Marketing Manchester may amend these terms at any time by posting changes online. Please review these terms regularly to ensure you are aware of any changes made by us. Your continued use of this site after changes are posted means you agree to be legally bound by these terms as updated and/or amended.
                </p>
                <h4 className={styles.termsTitle}>Alteration to the Terms and Conditions</h4>
                <p>
                    We may, at any time change, modify, add to or remove part or all of these Terms and Conditions. You should therefore check these Terms and Conditions periodically to see if they have changed.
                    Your continued use of this Website following any change in the Terms and Conditions will be deemed to constitute acceptance by you of those changes.
                </p>

                <h4 className={styles.termsTitle}>Use of this site</h4>
                <p>
                    You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use the site content in any way except for your own personal, non-commercial use.
                    You also agree not to adapt, alter or create a derivative work from any the site content except for your own personal, non-commercial use. Any other use of the site content requires the prior written permission of Marketing Manchester.
                </p>
                <p>You agree to use this site only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of this site. Prohibited behaviour includes harassing or causing distress or inconvenience to any person, transmitting obscene or offensive content or disrupting the normal flow of dialogue within this site.</p>
                <p></p>
                <h4 className={styles.termsTitle}>Disclaimers and Limitation of Liability</h4>
                <p>
                    The site content, including the information, names, images, pictures, logos and icons regarding or relating to Greater Manchester, its locations, events and services (or to third party providers of products and services),
                    is provided "AS IS" and on an "IS AVAILABLE" basis without any representations or any kind of warranty made (whether express or implied by law) to the extent permitted by law, including the implied warranties of satisfactory quality,
                    fitness for a particular purpose, non-infringement, compatibility, security and accuracy.
                </p>
                <p>
                    Under no circumstances will Marketing Manchester be liable for any of the following losses or damage (whether such losses where foreseen, foreseeable, known or otherwise): (a) loss of data; (b) loss of revenue or anticipated profits; (c) loss of business; (d) loss of opportunity; (e) loss of goodwill or injury to reputation;
                    (f) losses suffered by third parties; or (g) any indirect, consequential, special or exemplary damages arising from the use of this site regardless of the form of action.
                </p>
                <p>
                    Marketing Manchester does not warrant that functions contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the server that makes it available are free of viruses or bugs.
                </p>
                <h4 className={styles.termsTitle}>Intellectual Property</h4>
                <p>
                    The names, images and logos identifying this site, Marketing Manchester or third parties and their products and services are subject to copyright, design rights and trade marks of this site, Marketing Manchester and/or third parties. Nothing contained in these terms shall be construed as conferring by implication or otherwise any licence or right to use any trademark,
                    patent, design right or copyright of the this site, Marketing Manchester, or any other third party.
                </p>
                <h4 className={styles.termsTitle}>General</h4>
                <p>
                    If there is any conflict between these terms and specific terms appearing elsewhere on this site then the latter shall prevail.
                </p>
                <p>
                    If any of these terms are determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these terms are intended to be effective, then to the extent and within the jurisdiction in which that term is illegal, invalid or unenforceable,
                    it shall be severed and deleted from these terms and the remaining terms shall survive, remain in full force and effect and continue to be binding and enforceable.
                </p>
                <p>
                    These terms shall be governed by and interpreted in accordance with the laws of England and Wales.
                </p>
            </div>
        </>
    );
};