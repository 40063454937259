import React from "react";
import {
    FacebookShareButton, FacebookIcon,
    EmailShareButton, EmailIcon,
    TwitterShareButton, TwitterIcon,
    LinkedinShareButton, LinkedinIcon
} from 'react-share';

import Styles from "./social.module.css";

export const Social = ({ socialtype, title }) => {

    return (
        <>
            <div className={Styles.socialContainer}>
            <h2>{`Share this ${socialtype}` }</h2>
            <div className={Styles.socialsection}>
                <div>
                    <EmailShareButton
                    url={window.location.href}
                    subject={title}
                    body={`${title}`}
                    separator="-"
                    >
                    <EmailIcon size={32} round />
                    </EmailShareButton>
                </div>
                <div>
                <FacebookShareButton
                    url={window.location.href}
                    quote={title}
                    hashtag={`#${title}`}
                >
                    <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </div>
                <div>
                <TwitterShareButton
                    url={window.location.href}
                    title={title}
                    via="Marketing Manchester"
                    hashtags={[`#${title}`]}
                >
                    <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>
                <div>
                <LinkedinShareButton
                    url={window.location.href}
                    title={title}
                    summary={`Marketing Manchester ${socialtype}`}
                    source="https://www.marketingmanchester.com"
                >
                    <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
                </div>
            </div>
        </>
    )



}