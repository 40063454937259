import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import RevealStyles from "../animations.module.css";



export const SlideLeft = ({ children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();
    

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <>
            
            <div ref={ref} className={RevealStyles.slideContentContainer}>
                <motion.div
                    variants={{
                        hidden: { opacity: 0, x: -100 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                    >
                    {children}
                </motion.div>
            </div>
               
        </>
    );
};