import React, { useState } from "react";
import Styles from "./filterby.module.css";

import { ColourBGSwitch } from "../colourswitch/colourswitch";
import { filterItemsCategories } from "../../helpers";

export const FilterBy = ({ latestItems, originalItems, filters, filterSelected, setDisplayItems, setFilterSelected }) => {
    const [showhide, setShowHide] = useState(false);
    const handleClick = () => {
        setShowHide(!showhide);
    }

    let toggleClass = showhide ? `${Styles.active}` : null;
    let arrowClass = showhide ? `${Styles.rotate}` : null;
    let border = showhide ? `${Styles.grow}` : null;

    const filterItems = (filter) => {
        let filterItems = filterItemsCategories(filter, originalItems, latestItems);
        setDisplayItems(filterItems);
    }

    const renderFilters = () => {
        return(
            filters.map((filter) => (
                <div className={Styles.pointer} onClick={(ev) => handleFilter(ev, filter)} key={filter}>
                    <li className={`${Styles.sectorListItemContainer}`}>
                    <div className={`${Styles.sectorListOverlay} ${ColourBGSwitch(filter)}`}></div>
                        <span className={`${Styles.sectionListItem}`}>{filter}</span>
                    </li>
                </div>
            ))
        )
    }

    const handleFilter = (ev, selected) => {
        ev.preventDefault();
        filterItems(selected);
        setFilterSelected(selected)
    };

    return (
        <>
            <p onClick={handleClick} className={`${Styles.pointer}`}>Choose sector: <span className={`${Styles.midGrey}`}>{filterSelected}</span> <span className={`${Styles.icon} ${arrowClass}`}><span style={{ fontSize: `12px` }} className="gcicon-right-open"> </span></span></p>
            <div className={`${Styles.sectorListContainer} ${border}`}>         
                <ul className={`${Styles.sectorList} ${toggleClass}`}>
                    <div className={Styles.pointer} onClick={(ev) => handleFilter(ev, 'Latest')}>
                        <li className={`${Styles.sectorListItemContainer}`}>
                            <div className={`${Styles.sectorListOverlay} ${ColourBGSwitch("Latest")}`}></div>
                            <span className={`${Styles.sectionListItem}`}>Latest</span>
                        </li>
                    </div>
                    { renderFilters() }
                </ul>
            </div>
        </>
    );
};