import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import {
    setDescription,
    setTitle,
    populateEventsData
} from "../../utils";
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import { ColourSwitch, ColourBGSwitch } from "../../components/colourswitch/colourswitch";
import { getParsedDate } from "../../helpers";
import Styles from "./events.module.css";
import Btn from "../../components/Button/Button";
import { Reveal } from "../../components/animations/reveal/reveal";

export const Events = () => {
    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 75,
        },
        animate: (idx) => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5, delay: 0.15 * idx,
            }
        }),
    };

    useEffect(() => {
        setTitle("Marketing Manchester | Events");
        setDescription("See Marketing Manchester's upcoming national & international events for partners & stakeholders in the visitor economy, place-making & property sectors & find out how to get involved");
    }, []);

    const fetchEvents = async () => {
        try {
            setIsLoading(true);
            let data = await populateEventsData();
            setDisplayItems(data.eventItems);
        } catch (error) {
            setError({ message: "Uh oh, looks like something went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    function renderHtml(output) {
        let ret = output;
        let ico = `<i class="gcicon-map-marker"></i>&nbsp;`;
        let idx = output.indexOf('>', 0);
        ret = [ret.slice(0, idx + 1), ico, ret.slice(idx + 1,ret.length)].join('');
        return React.createElement("div", { dangerouslySetInnerHTML: { __html: ret } });
    }

    return (
        <>
            <div className={Styles.eventsPageContainer}>
                <section className={Styles.eventsSection}>
                    <div className={Styles.eventsSectionTitle}>
                        <Reveal>
                            <h1>Manchester Partnership Event Programme</h1>
                        </Reveal>
                        <Reveal>
                            <h3 className={Styles.normal}>Marketing Manchester host series of events throughout the year available to The Manchester Partnership. These events span the key sectors we work in and aim to increase networking, promote the region and provide insight.</h3>
                        </Reveal>
                    </div>
                </section>
            </div>
            <div className={`${Styles.sectorContainer}`}>
                <div className={`${Styles.eventsContainer}`}>
                {
                    isLoading ? <Loader page="Events" /> :
                        error ? <Error error={error} id="dashboard-error" page="Events" /> :
                        displayItems.map((event, idx) => (
                            <motion.div
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                }}
                                custom={idx}
                            >   
                                <div className={`${Styles.eventsCardStyle} ${Styles.ltGreyBg}`} key={idx }>
                                    <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${event.bannerImage})` }} className={`${Styles.eventsCardImage}`}></div>
                                    <div className={`${Styles.cardOverlayContainer}`}>
                                        <div className={`${Styles.cardOverlay} ${ColourBGSwitch(event.category)}`}></div>
                                        <div className={`${Styles.eventsContent}`} >
                                            <div>
                                                <p className={`${Styles.eventsContentMargin}`}><span className={`${Styles.sectorMarker} ${ColourSwitch(event.category)}`}>|</span> <span className="sectorName">{event.category} </span></p>
                                                <Link className={`${Styles.eventHeaderStyle} ${Styles.eventsContentMargin}`} as={Link} to={`${event.urlSegment}`}>
                                                    {event.title}
                                                </Link>
                                                <p className={`${Styles.noMargin}`}><span className="gcicon-pin" /> {event.location}</p>
                                                <p className={`${Styles.noMargin}`}> <span className="gcicon-calendar"> </span><span className="date">{getParsedDate(event.eventDate)}</span></p>

                                                { renderHtml(event.eventAddress)}
                                            </div>
                                            <div className={`${Styles.eventsButton}`} style={{ textAlign: `right` }}>
                                                <Link as={Link} to={`${event.urlSegment}`}>
                                                    <Btn text={"Find out more"} color="Black" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                           
                        ))
                }
                </div>
            </div>
        </>
    );
};