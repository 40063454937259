import React from "react";
import TeamCardStyles from "./teamCard.module.css";

export const TeamCard = (props) => {

    return (

        <>
            <div className={`${TeamCardStyles.teamCardStyle} ${TeamCardStyles.teamCardImage}`} style={{ backgroundImage: `url(${props.image})` }}>
                <div className={`${TeamCardStyles.teamCardOverlayContainer}`} >
                    <div className={`${TeamCardStyles.teamCardOverlay} ${(props.colour)}`}></div>
                    <div className={`${TeamCardStyles.teamContent}`} >
                        <h3 className={`${TeamCardStyles.teamContentMargin}`}>{props.name}</h3>
                        <p className={`${TeamCardStyles.teamContentMargin}`}>{props.position}</p>
                        <a className={`${TeamCardStyles.iconHoverStyle} ${TeamCardStyles.icon}`} href={props.linkedin} target="_blank" rel="noreferrer">
                            <span className="gcicon-linkedin"></span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};