import React, { useRef } from "react";
import { motion, useScroll, useTransform} from "framer-motion";
import { Link } from "react-router-dom";
import HeroStyles from "./hero.module.css";
import Btn from "../Button/Button";
import { Reveal } from "../animations/reveal/reveal";

export const Hero = (props) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });
    
    const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "25%"]);
    const textY = useTransform(scrollYProgress, [0, 1], ["0%", "-40%"]);

    return (
        <>
            <div ref={ref} className={props.height === "100vh" ? HeroStyles.heroContainerParallax : HeroStyles.subHeroContainerParallax} style={{ height: `${props.height}` }}
            >
                <div className={props.height === "100vh" ? HeroStyles.gradientOverlay : HeroStyles.subGradientOverlay} style={{ height: `${props.height}` }}>
                    <motion.div
                        style={{ y: textY }}
                        className={HeroStyles.heroContentContainer}
                    >
                        <div className={HeroStyles.heroContent} >
                            <Reveal>
                                <h1 className="white">{props.title}</h1>
                            </Reveal>
                            <Reveal>
                                {
                                    props.subTitle ?
                                        <h2 className="white">{props.subTitle}</h2>
                                        :
                                        <span className={HeroStyles.hide}></span>
                                }
                            </Reveal>
                            <Reveal>
                                {
                                    props.btnText1 ?
                                        <Link as={Link} to={props.link1} href={props.link1}><Btn text={props.btnText1} color="White" /></Link>
                                        :
                                        <div className={HeroStyles.hide}></div>
                                }
                                {
                                    props.btnText2 ?
                                        <Link as={Link} to={props.link2} href={props.link2}><Btn text={props.btnText2} color="Teal" /></Link>
                                        :
                                        <div className={HeroStyles.hide}></div>
                                }
                            </Reveal>
                        </div>
                        <div className={HeroStyles.mobHide} ></div>
                    </motion.div>
                </div>
                <motion.div style={{ backgroundImage: `url(${props.image})`, y: backgroundY }} className={HeroStyles.heroImageBg}>
                </motion.div>
            </div>
        </>
    );
};