import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import {
    setDescription,
    setTitle,
    populateNewsData
} from "../../utils";

import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import Styles from "./home.module.css";
import Btn from "../../components/Button/Button";
import image from "../../assets/images/mm-who-we-are-sm.jpg";
import WorkWithUsImage from "../../assets/images/work-with-us/st-peters-square-pano-large.jpg"
import { Hero } from "../../components/hero/heroParallax"
import heroImage from "../../assets/images/mm-home-hero.jpg";
import { NewsCard } from "../../components/NewsCard/newsCard"
import NewsCardStyles from "../../components/NewsCard/newsCard.module.css";
import NewsStyles from "../news/news.module.css";
import MarketingBanner from "../../components/MarketingBanner/MarketingBanner";
import { EventBanner } from "../../components/eventBanner/eventBanner";
import EventBannerStyles from "../../components/eventBanner/eventBanner.module.css";
import { Reveal } from "../../components/animations/reveal/reveal";
import { RevealDelay } from "../../components/animations/revealDelay/revealDelay";
import { SlideRight } from "../../components/animations/slideRight/slideRight";

const Home = () => {

    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 75,
        },
        animate: (idx) => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5, delay:0.15 * idx,
            }
        }),
    };

    const fetchNews = async () => {
        try {
            setIsLoading(true);
           
            let data = await populateNewsData();
            // array ordered in api
            let latestNews = data.newsItems.filter((n) => n.display===true).slice(0, 6);

            setDisplayItems(latestNews);
            } catch (error) {
                setError({ message: "Uh oh, looks like something went wrong" });
            } finally {
                setIsLoading(false);
            }
    };

    useEffect(() => {
        fetchNews();
    }, []);

    useEffect(() => {
        setTitle("Marketing Manchester - Greater Manchester's official destination marketing organisation");
        setDescription("Marketing Manchester is the agency charged with promoting Greater Manchester nationally & internationally as a place to visit, invest, meet, and study & is the city-region's official DMO");
    }, []);

    return (
        <>
            <Hero
                image={heroImage}
                height="100vh"
                title="Marketing Manchester"
                subTitle="Promoting Greater Manchester to a global audience as a place to visit, invest, meet and study."
                btnText1="More about us"
                link1="/whoWeAre"
                btnText2="Work with us"
                link2="/workWithUs"
            />
            <div className={Styles.homeContainer}>
                <section className={Styles.sectionContainer}>
                    <div>
                        <Reveal>
                            <h1>Greater Manchester's promotional agency</h1>
                        </Reveal>
                        <Reveal>
                        <h2>We are the city-region's Destination Marketing Organisation, responsible for the strategic positioning of Greater Manchester and the development of its promotional brand framework.</h2>
                        </Reveal>
                        <Reveal>
                            <p>
                            We lead on the visitor economy and incorporate the Manchester Convention Bureau and Sports Bidding Unit. We deliver campaigns in partnership with MIDAS focused on the promotion of frontier sectors including digital,
                            innovation and green, and undertake a range of place promotion activities, for capital investment and real estate sector.
                            </p>
                        </Reveal>
                        <Reveal>
                            <Link as={Link} to="/whoWeAre" href="/whoWeAre"><Btn text={"Find out more"} color="Black" /></Link>
                        </Reveal>
                    </div>
                    <SlideRight>
                        <Link as={Link} to="/whoWeAre" href="/whoWeAre"><div style={{ backgroundImage: `url(${image})` }} className={`${Styles.imageBg} ${Styles.imageHeight1}`}></div></Link>
                    </SlideRight>

                </section>

                <section className={Styles.noMarginTb}>
                    <Reveal>
                        <MarketingBanner />
                    </Reveal>
                </section>

                <section className={Styles.sectionWorkWithUs}>
                    <Reveal>
                    <div style={{ backgroundImage: `url(${WorkWithUsImage})` }} className={Styles.sectionWorkWithUsContainer}>
                        <div className={`${Styles.gradientOverlay} ${Styles.nested}`}>
                            <div>
                                <h3 className={Styles.white}>Work with us</h3>
                                <h2 className={Styles.white}>Find out why and how you can work with Marketing Manchester</h2>
                                <Link as={Link} to="/workWithUs" href="/workWithUs"><Btn text={"Find out more"} color="Teal" /></Link>
                            </div>
                            <div>

                            </div>
                        </div>
                        </div>
                    </Reveal>
                </section>
            </div>

            <div className={`${Styles.carouselContainer} ${Styles.ltGreyBg}`}>
                <section>
                    <Reveal>
                        <h1>Latest news</h1>
                    </Reveal>
                    <Reveal>
                        <h3>The latest news in Greater Manchester from our priority sectors</h3>
                    </Reveal>
                    
                    <div className={`${Styles.newsContainer}`} >
                        {
                            isLoading ? <Loader page="News" /> :
                                error ? <Error error={error} id="dashboard-error" page="Home|news-page" /> :
                                    displayItems?.length === 0 ?

                                    <h4>No results found</h4>
                                    :
                                        displayItems.map((item, idx) => (
                                            item.display === true &&
                                            <div className={`${Styles.newsIdxContainer} `} key={idx}>
                                                    <motion.div
                                                        variants={fadeInAnimationVariants}
                                                        initial="initial"
                                                        whileInView="animate"
                                                        viewport={{
                                                            once: true,
                                                        }}
                                                        custom={idx}
                                                    >   
                                                    <NewsCard
                                                        idx={idx}
                                                        bannerImage={item.bannerImage}
                                                        category={item.category}
                                                        articleDate={item.articleDate}
                                                        headLine={item.headLine}
                                                        summary={item.summary}
                                                        id={'/news/' + item.id}
                                                        url={'/news/' + item.urlSegment}
                                                        cardBgColour={`${NewsCardStyles.cardBgWhite}`}
                                                            />
                                                </motion.div>

                                            </div>
                                    ))
                        }
                    </div>

                </section>
            </div>

            <section>
                <Reveal>
                    <h1>Our events</h1>
                </Reveal>
               
                <EventBanner
                    eventBannerBgColour={`${EventBannerStyles.eventBannerBgLtGrey}`}
                    />
               
            </section>

        </>
     );
};

export default Home;