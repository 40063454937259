
import styles from "./Accessibility.module.css"

export const Accessibility = () => {
    return (
        <>
            
            <div className={styles.accessContainer}>
                <h1 className={styles.accessTitle}>Marketing Manchester Accessibility Statement</h1>
                <p>November 2020</p>
                <h3 className={styles.accessTitle}>General</h3>
                <p>
                    Marketing Manchester strives to ensure that its services are accessible to people with disabilities. Marketing Manchester has invested a significant amount of resources to help ensure that its website is made easier to use and more accessible for people with disabilities, with the strong belief that website accessibility efforts assist all users and that every person has the right to live with dignity, equality, comfort and independence.
                </p>
                <h3 className={styles.accessTitle}>Accessibility on www.marketingmanchester.com</h3>
                <p>
                    www.marketingmanchester.com makes available the <a className={styles.link} href="https://userway.org/" target="_blank" rel="noreferrer">UserWay's Web Accessibility Widget</a> is powered by a dedicated accessibility server. The software allows www.marketingmanchester.com to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).
                </p>
                <h3 className={styles.accessTitle}>Enabling the Accessibility Menu</h3>
                <p>
                    The www.marketingmanchester.com accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.The www.greeneconomy.co.uk accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.
                </p>
                <h3 className={styles.accessTitle}>Disclaimer</h3>
                <p>
                    Marketing Manchester continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities.
                </p>
                <p>
                    In an ongoing effort to continually improve and remediate accessibility issues, we also regularly scan www.marketingmanchester.com with UserWay's <a className={styles.link} href="https://userway.org/scanner" target="_blank" rel="noreferrer">Accessibility Scanner</a> to identify and fix every possible accessibility barrier on our site. Despite our efforts to make all pages and content on www.marketingmanchester.com fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.
                </p>
                <h3 className={styles.accessTitle}>Here For You</h3>
                <p>
                    If you are experiencing difficulty with any content on www.marketingmanchester.com or require assistance with any part of our site, please contact us during normal business hours as detailed below and we will be happy to assist.
                </p>
                <h3 className={styles.accessTitle}>Content that's not within the scope of the accessibility regulations</h3>
                <p>
                    PDFs and other documents. Many of our older PDFs and Word documents may not meet accessibility standards - for example, they may not be structured so they're accessible to a screen reader.
                    This doesn't meet WCAG 2.1 success criterion 4.1.2 (name, role value). Some of our PDFs and Word documents are essential to providing our services. For example, we have PDFs with information on how users can access our services,
                    and forms published as Word documents. The accessibility regulations don't require us to fix PDFs or other documents published before 23 September 2018 if they're not essential to providing our services.
                    We're aware of these accessibility issues and are working to fix them. New PDFs or Word documents will meet accessibility standards, unless we determine that they represent a disproportionate burden within the meaning of the
                    accessibility regulations. For example, publications created for print and published on the website. We've assessed our resources and the cost of fixing issues with the above publications and we believe that doing so now would
                    be a disproportionate burden within the meaning of the accessibility regulations. These documents typically may not meet the following
                    WCAG 2.1 success criteria: 1.1.1 non-text content 1.3.1 information and relationships 1.3.2 meaningful sequence 2.1.1 keyboard 1.4.3 contrast (minimum) 1.4.5 images of text 1.4.10 reflow 1.4.12 text spacing.
                </p>
                <h3 className={styles.accessTitle}>Contact Us</h3>
                <p>
                    If you wish to report an accessibility issue, have any questions or need assistance, please contact Marketing Manchester as follows:
                </p>
                <p>Email: <a href="mailto:info@marketingmanchester.com" className={styles.link}>info@marketingmanchester.com</a></p>
            </div>
        </>
    );
};