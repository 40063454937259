import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import ScrollToTop from "./utils/ScrollToTop";

import "./assets/css/custom.css";
import "./assets/css/gcicons.css";

import { Layout } from "./components/layout/layout";
import Home from "./pages/home/home";
import { PageNotFound } from "./pages/pageNotFound/PageNotFound";
import { WhoWeAre } from "./pages/whoWeAre/whoWeAre";
import { WhatWeDo } from "./pages/whateWeDo/whatWeDo";
import { WorkWithUs } from "./pages/workWithUs/workWithUs";
import { News } from "./pages/news/news";
import { Events } from "./pages/events/events";
import { EventsItem } from "./pages/eventsItem/eventsItem";
import { Contact } from "./pages/contact/contact";
import { IndividualNews } from "./pages/individualNews/individualNews";
import { AnnualReview } from "./pages/annualReview/annualReview";
import { Terms } from "./pages/terms/Terms";
import { Accessibility } from "./pages/accessibility/Accessibility";
import { Privacy } from "./pages/privacy/Privacy";
import { Cookies } from "./pages/cookies/Cookies";
import { Resources } from "./pages/resources/resources";

export default function App() {

    return (
        <>
            <Router>
                <ScrollToTop />
                <Layout>
                    <Routes>

                        {/*Public pages*/}
                        <Route exact path="/" element={<Home />} />
                        <Route path="/whoweare" element={<WhoWeAre />} />
                        <Route path="/whatwedo" element={<WhatWeDo />} />
                        <Route path="/workWithUs" element={<WorkWithUs />} />
                        <Route exact path="/news" element={<News />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/eventsitem" element={<EventsItem />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route exact path="/news/:newsId" element={<IndividualNews />} />
                        <Route exact path="/events/:eventId" element={<EventsItem />} />
                        {/*<Route path="/marketplace/:supplierName" element={<IndividualSuppliers />} />*/}
                        {/*<Route path="/registerUser/:accountId" element={<RegisterUser auth={auth} />} />*/}
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/our-annual-review" element={<AnnualReview />} />
                        <Route path="/terms-and-conditions" element={<Terms />} />
                        <Route path="/accessibility-statement" element={<Accessibility />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/cookie-policy" element={<Cookies />} />
                        <Route path="/resources" element={<Resources />} />
                    </Routes>
                </Layout>
            </Router>
        </>
    );

}

