import { useState, useEffect } from "react";
import { getNewsDetail, getEventsDetail } from "../../utils";

export const useIndividual = (detailId, detailType) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = {};
                switch (detailType) {
                    case "News":
                        data = await getNewsDetail(detailId);
                        break;
                    case "Events":
                        data = await getEventsDetail(detailId);
                        break;
                    default:
                        break
                }
                setData(data);
                setIsLoading(false);
            } catch (error) {
                setError({ message: "Uh oh, looks like something went wrong" });
                setIsLoading(false);
            }
        };
        fetchData();
    }, [detailId]);

    return {
        data,
        error,
        isLoading,
    };
};