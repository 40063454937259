import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
    populateMarketingData,
    saveMarketingDataLocalSession,
    getMarketingDataFromLocalSession
} from "../../utils";

import Carousel from 'react-bootstrap/Carousel';
import MarketingBannerStyles from "./marketingBanner.module.css";
import "./marketingBannerControls.css";
import Btn from "../../components/Button/Button";
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";

const MarketingBanner = () => {

    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchMarketing = async () => {
        try {
            setIsLoading(true);
            let data = getMarketingDataFromLocalSession();
            if (!data) {
                data = await populateMarketingData();
                saveMarketingDataLocalSession(data);
            }
            setDisplayItems(data);
        } catch (error) {
            setError({ message: "Uh oh, looks like something went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMarketing();
    }, []);

    const indicator = () => {
        let indi = true;
        if (displayItems) {
            if (displayItems.length === 1) indi = false;
        }
        return indi;
    }

    if (error) {
        return <Error error={error} id="marketing-error" page="home-page" />;
    }

    return (
        <>
            {
                isLoading ? <Loader page="Home Marketing" /> :
                    <Carousel indicators={indicator()} controls={false} pause='hover' variant="dark" id="marketingBanner">

                        {
                            displayItems.map((marketing, idx) => (

                                <Carousel.Item interval={5000} style={{ height: `auto` }} key={idx}>
                                    <div style={{ backgroundColor: `#${marketing.bgColour}` }} className={`${MarketingBannerStyles.carouselWrapper}`} >
                                        <div>
                                            <Carousel.Caption className={`${MarketingBannerStyles.carouselCaption}`} >
                                                <h3>{marketing.title}</h3>
                                                <h2>{marketing.subTitle}</h2>
                                                <Link
                                                    to={`${marketing.buttonLink}`}
                                                >
                                                    <Btn text={`${marketing.button}`} color="Black" />
                                                </Link>
                                            </Carousel.Caption>
                                        </div>

                                        {
                                            marketing.videoUrl !== "" ?
                                            <div className={`${MarketingBannerStyles.video}`}>
                                                    {/*<iframe src="https://www.youtube.com/embed/dsO_q8bCQ-E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>*/}
                                                    <iframe src={`${marketing.videoUrl}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                            </div> :
                                            <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${marketing.bannerImage}` }} className={`${MarketingBannerStyles.marketingBannerImage}`} ></div>
                                        }
                                                                                                                    
                                    </div>
                                </Carousel.Item>

                            ))
                        }

                    </Carousel>
            }
        </>
    )
}

export default MarketingBanner;