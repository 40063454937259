import React, { useState, useEffect } from "react";

import {
    setDescription,
    setTitle,
    populateWhatWeDoData,
    saveWhatWeDoLocalSession,
    getWhatWeDoFromLocalSession
} from "../../utils";

import Styles from "./whatWeDo.module.css";
import "./whatWeDo.css"

import { renderBullets, renderButton } from "../../helpers";
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";

import { Hero } from "../../components/hero/heroParallax"
import heroImage from "../../assets/images/what-we-do/manchester-40-hero.jpg";
import { Reveal } from "../../components/animations/reveal/reveal";
import { FadeIn } from "../../components/animations/fadeIn/fadeIn";
//import { SlideRight } from "../../components/animations/slideRight/slideRight";
//import { SlideLeft } from "../../components/animations/slideLeft/slideLeft";

export const WhatWeDo = () => {
    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setTitle("Marketing Manchester | What we do");
        setDescription("Marketing Manchester raises the profile of Greater Manchester as a global region, strategically leading the visitor economy and supporting inward investment through sector marketing");
    }, []);

    const fetchWhat = async () => {
        try {
            setIsLoading(true);
            let data = getWhatWeDoFromLocalSession();
            if (!data) {
                data = await populateWhatWeDoData();
                saveWhatWeDoLocalSession(data);
            }
            setDisplayItems(data);
        } catch (error) {
            setError({ message: "Uh oh, looks like something went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchWhat();
    }, []);

    return (
        <>
            <Hero
                image={heroImage}
                height="75vh"
                title="What we do"
            />
            <div className={`${Styles.pageContainer}`}>
                {
                    isLoading ? <Loader page="What we do" /> :
                        error ? <Error error={error} id="dashboard-error" page="Whatwedo" /> :
                        displayItems.slice(0, 10).map((what, idx) => (

                            <div className={`whatWeDoSectionContainer${idx + 1} 
                                ${idx === 1 ? Styles.ecoGreenBg : ''} ${idx === 2 ? Styles.creamBg : ''} ${idx === 3 ? Styles.ltYellowBg : ''} ${idx === 4 ? '' : ''} ${idx === 5 ? Styles.ltRedBg : ''} ${idx === 6 ? Styles.creamBg : ''} ${idx === 7 ? Styles.ltCameoGreenBg : ''} ${idx === 8 ? Styles.creamBg : ''} ${idx === 9 ? Styles.yellowBg_10 : ''}`}
                                key={what.id}>
                                {idx % 2 === 0 || idx === 0 ?
                                    <div className={`whatWeDoSection${idx + 1}`} >
                                        <div>
                                            <Reveal>
                                                <h1>{what.title}</h1>
                                            </Reveal>
                                            <Reveal>
                                                <h3>{what.mainText}</h3>
                                            </Reveal>
                                            <Reveal>
                                                {renderBullets(what)}
                                            </Reveal>
                                            <Reveal>
                                                <p>{what.subText}</p>
                                            </Reveal>
                                            <Reveal>
                                                {renderButton(what)}
                                            </Reveal>
                                        </div>
                                            <FadeIn>
                                                <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${what.bannerImage})` }} className={`whatWeDoImage${idx + 1}`}></div>
                                            </FadeIn>
                                    </div>
                                    :
                                    <div className={`Section${idx + 1}`} >
                                        <FadeIn>
                                            <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${what.bannerImage})` }} className={`whatWeDoImage${idx + 1}`}></div>
                                        </FadeIn>
                                        <div>
                                            <Reveal>
                                                <h1>{what.title}</h1>
                                            </Reveal>
                                            <Reveal>
                                                <h3>{what.mainText}</h3>
                                            </Reveal>
                                            <Reveal>
                                                {renderBullets(what)}
                                            </Reveal>
                                            <Reveal>
                                                <p>{what.subText}</p>
                                            </Reveal>
                                            <Reveal>
                                                {renderButton(what)}
                                            </Reveal>
                                        </div>

                                    </div>
                                }
                            </div>
                        ))
                }
            </div>

        </>
    );
};