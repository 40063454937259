import "./loader.css";

export const Loader = ({ page }) => {
    return (
        <div data-testid="loader" className={`${page}`}>
            <div className={"loaderContainer"}>
                <span className={"loader"}></span>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

