import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion, useScroll, useMotionValueEvent, useAnimate } from "framer-motion";
import Styles from "./navmenu.module.css";

import logo from "../../assets/images/logo/marketing-manchester-blk.svg";

export const Navmenu = () => {
    const location = useLocation();
    const path = location.pathname;


    const [menuState, setMenuState] = useState(false);

    const handleClick = () => {
        setMenuState(menuState => !menuState);
    }

    let toggleClass = menuState ? `${Styles.active} ${Styles.relative}` : null;

    //const [scope, animate] = useAnimate()

    //useEffect(() => {
    //    const animations = async () => {
    //        await animate(scope.current, { y: 0 }, { delay:1, duration: 0.5, ease: "easeInOut" })
    //        await animate("#logo", { scale: 1 }, { delay: 0.5, type: 'spring', stiffness: 100 })
    //    }
    //    animations()
    //})

    const { scrollY } = useScroll();
    const [hidden, setHidden] = useState(false);

    useMotionValueEvent(scrollY, "change", (latest) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    return (
        <>
            <AnimatePresence>
                <motion.div
                    initial={{ y: -100 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.5, duration: 0.5, ease: "easeInOut" } }
                    className={Styles.navContainer}
                >
                <motion.div
                    key={1}
                    variants={{
                        visible: { y: 0 },
                        hidden: { y: "-101%" },
                    }}
                    animate={hidden ? "hidden" : "visible"}
                    transition={{ duration: 0.5, ease: "easeInOut" }}

                    className={Styles.navContainer}

                >

                    <Navbar collapseOnSelect variant="Light" expand="lg" className={`${Styles.navbar} navbar`}>
                        <Navbar.Brand className={Styles.brand} href="/">
                                <motion.img
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                    transition={{ delay: 1, type: 'spring', damping: 10, stiffness: 100 } }
                                
                                className={Styles.logo} src={logo} alt="logo" />
                        </Navbar.Brand>

                        <Navbar.Toggle onClick={handleClick} className={`${toggleClass} ${Styles.navbarToggler} ${Styles.collapsed}`} type="button" aria-controls="basic-navbar-nav" data-bs-toggle="collapse" data-bs-target="#basic-navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className={`${Styles.togglerIcon} ${Styles.topBar}`}></span>
                            <span className={`${Styles.togglerIcon} ${Styles.middleBar}`}></span>
                            <span className={`${Styles.togglerIcon} ${Styles.bottomBar}`}></span>
                        </Navbar.Toggle>

                        {/* <button className={`${Styles.navbarToggler} ${Styles.collapsed} ${Styles.dFlex} ${Styles.dLgNone} ${Styles.flexColumn} ${Styles.justifyContentAround}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className={`${Styles.togglerIcon} ${Styles.topBar}`}></span>
                    <span className={`${Styles.togglerIcon} ${Styles.middleBar}`}></span>
                    <span className={`${Styles.togglerIcon} ${Styles.bottomBar}`}></span>
                </button>
                */}
                        <Navbar.Collapse id="basic-navbar-nav" className={Styles.navLinkContainer}>
                            <Nav>
                                <div className={Styles.navItemsContainer}>
                                    <Nav.Link onClick={handleClick}
                                        className={`${Styles.navLink} ${path === "/whoweare" ? Styles.current : null
                                            }`}
                                        as={Link}
                                        to="/whoweare"
                                        href="/whoweare"
                                    >
                                        Who we are
                                        <div></div>
                                    </Nav.Link>
                                    <Nav.Link onClick={handleClick}
                                        className={`${Styles.navLink} ${path === "/whatwedo" ? Styles.current : null
                                            }`}
                                        as={Link}
                                        to="/whatwedo"
                                        href="/whatwedo"
                                    >
                                        What we do
                                        <div></div>
                                    </Nav.Link>
                                    <Nav.Link onClick={handleClick}
                                        className={`${Styles.navLink} ${path === "/workwithus" ? Styles.current : null
                                            }`}
                                        as={Link}
                                        to="/workwithus"
                                        href="/workwithus"
                                    >
                                        Work with us
                                        <div></div>
                                    </Nav.Link>
                                    <Nav.Link onClick={handleClick}
                                        className={`${Styles.navLink} ${path === "/news" ? Styles.current : null
                                            }`}
                                        as={Link}
                                        to="/news"
                                        href="/news"
                                    >
                                        News
                                        <div></div>
                                    </Nav.Link>
                                    <Nav.Link onClick={handleClick}
                                        className={`${Styles.navLink} ${path === "/events" ? Styles.current : null
                                            }`}
                                        as={Link}
                                        to="/events"
                                        href="/events"
                                    >
                                        Events
                                        <div></div>
                                    </Nav.Link>
                                    <Nav.Link onClick={handleClick}
                                        className={`${Styles.navLink} ${path === "/contact" ? Styles.current : null
                                            }`}
                                        as={Link}
                                        to="/contact"
                                        href="/contact"
                                    >
                                        Contact
                                        <div></div>
                                    </Nav.Link>
                                    {/*<Link*/}
                                    {/*    className={`${Styles.navLink} ${path === "/about-us" ? Styles.current : null*/}
                                    {/*        }`}*/}
                                    {/*    as={Link}*/}
                                    {/*    to="/about-us"*/}
                                    {/*    href="/about-us"*/}
                                    {/*>*/}
                                    {/*    Partners*/}
                                    {/*</Link>*/}
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    </motion.div>
            </motion.div>
            </AnimatePresence>
        </>
    );
};