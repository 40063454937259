import React, { useState, useEffect } from "react";
/*import { Link } from "react-router-dom";*/

import { Hero } from "../../components/hero/heroParallax"
import Styles from "./resources.module.css";
import "./resources.css";
import heroImage from "../../assets/images/resources/resources-hero.jpg";
import { Reveal } from "../../components/animations/reveal/reveal";
import { SlideLeft } from "../../components/animations/slideLeft/slideLeft";

export const Resources = () => {
   
    return (
        <> 
            <Hero
                image={heroImage}
                height="75vh"
                title="Resources"
            />

            <section className={`${Styles.resourceContentStyles}`}>
                <Reveal>
                    <h1>Need more help?</h1>
                </Reveal>
                <Reveal>
                    <h2>Check out our list of helpful resources below.</h2>
                </Reveal>

                <Reveal>
                    <div className={`${Styles.resourceContainer}`} style={{ backgroundColor: `#F9F8F0` }}>
                        <h4 className={`${Styles.resourceContentMargin}`}>Lorem ipsum dolor sit.</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>

                        <div className={`${Styles.linkContainer}`}>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                        </div>
                       
                    </div>
                </Reveal>

                <Reveal>
                    <div className={`${Styles.resourceContainer}`} style={{ backgroundColor: `#E0EAFC` }}>
                        <h4 className={`${Styles.resourceContentMargin}`}>Lorem ipsum dolor sit.</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>

                        <div className={`${Styles.linkContainer}`}>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                        </div>

                    </div>
                </Reveal>

                <Reveal>
                    <div className={`${Styles.resourceContainer}`} style={{ backgroundColor: `#FFE1DF` }}>
                        <h4 className={`${Styles.resourceContentMargin}`}>Lorem ipsum dolor sit.</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>

                        <div className={`${Styles.linkContainer}`}>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                            <ul>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                                <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                                    <li>Image Library</li>
                                </a>
                            </ul>
                        </div>

                    </div>
                </Reveal>
  
            </section>
        </>
    );
};