import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import RevealStyles from "../animations.module.css";



export const Reveal = ({ children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();
    /*const slideControls = useAnimation();*/

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
            /*slideControls.start("visible");*/
        }
    }, [isInView]);

    return (
        <>
            
            <div ref={ref} className={RevealStyles.revealContentContainer}>
                <motion.div
                    variants={{
                        hidden: { opacity: 0, y: 75 },
                        visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{ duration: 0.5, staggerChildren: 0.5 }}
                    >
                    {children}
                </motion.div>
               
            </div>
               
        </>
    );
};