import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import {
    setDescription,
    setTitle,
    populateNewsData,
    getNewsSelectedFromLocalSession,
    saveNewsSelectedToLocalSession
} from "../../utils";
import { filterItemsCategories } from "../../helpers";
import { FilterBy } from "../../components/filterby/filterby"
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import { NewsCard } from "../../components/NewsCard/newsCard"
import NewsCardStyles from "../../components/NewsCard/newsCard.module.css";
import NewsStyles from "../news/news.module.css";
import { RevealSlide } from "../../components/animations/revealSlide/revealSlide";
import { Reveal } from "../../components/animations/reveal/reveal";

export const News = () => {
    const [filterSelected, setFilterSelected] = useState("Latest");
    const [fullNnews, setFullNews] = useState([]);
    const [latestNnews, setLatestNews] = useState([]);
    const [categories, setCategories] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 75,
        },
        animate: (idx) => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5, delay: 0.15 * idx,
            }
        }),
    };

    const updateFilterSelected = (filter) => {
        setFilterSelected(filter);
        saveNewsSelectedToLocalSession(filter);
    }

    const fetchNews = async () => {
        try {
            setIsLoading(true);
            let data = await populateNewsData();

            setCategories(data.categories)
            setFullNews(data.newsItems);

            // array ordered in api
            let latestNews = data.newsItems.filter((n) => n.display === true).slice(0, 11);
            setLatestNews(latestNews);

            const filter = getNewsSelectedFromLocalSession();
            let filterItems = [...latestNews];
            if (filter) {
                filterItems = filterItemsCategories(filter, data.newsItems, latestNews);
                setFilterSelected(filter);
            }
            setDisplayItems(filterItems);
        } catch (error) {
            setError({ message: "Uh oh, looks like something went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchNews();
    }, []);

    useEffect(() => {
        setTitle("Marketing Manchester | News");
        setDescription("Find out the latest news from Marketing Manchester across the visitor economy & key sectors to see how we�re making Greater Manchester a globally leading place to visit, invest, meet & study");
    }, []);

    return (
        <>
            <div className={NewsStyles.newsPageContainer}>               
                <section className={NewsStyles.newsSection}>
                    <div>
                        <Reveal>
                            <h1>The latest news in Greater Manchester from our priority sectors</h1>
                        </Reveal>
                    </div>
                    <div className={NewsStyles.newsSectionTitle}>
                        <Reveal>
                        <FilterBy
                            latestItems={latestNnews}
                            originalItems={fullNnews}
                            filters={categories}
                            filterSelected={filterSelected}
                            setDisplayItems={setDisplayItems}
                            setFilterSelected={updateFilterSelected}
                            />
                        </Reveal>
                    </div>                   
                </section>
            </div>

            <div className={`${NewsStyles.sectorContainer}`}>
                <div className={`${NewsStyles.newsContainer}`}>
                    {
                        isLoading ? <Loader page="News" /> :
                            error ? <Error error={error} id="dashboard-error" page="News" /> :
                            displayItems?.length === 0 ?
                                <h4>No results found</h4>
                                :
                                    displayItems.map((item, idx) => (
                                        item.display === true &&
                                        <div className={`${NewsStyles.newsIdxContainer} ${idx === 0 ? NewsCardStyles.firstNewsItem : ''} `} key={idx}>
                                            <motion.div
                                                variants={fadeInAnimationVariants}
                                                initial="initial"
                                                whileInView="animate"
                                                viewport={{
                                                    once: true,
                                                }}
                                                custom={idx}
                                            >   
                                                <NewsCard
                                                    idx={idx}
                                                    bannerImage={item.bannerImage}
                                                    category={item.category}
                                                    articleDate={item.articleDate}
                                                    headLine={item.headLine}
                                                    summary={item.summary}
                                                    id={item.id}
                                                    url={item.urlSegment }
                                                    cardBgColour={`${NewsCardStyles.cardBgWhite}`}
                                                />
                                            </motion.div>
                                    </div>
                                ))
                    }                  
                </div>
            </div>

        </>
    );
};