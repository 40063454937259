import React, { useEffect, useState } from "react";
import {
    populateEventsData,
} from "../../utils";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import Styles from "./eventBanner.module.css";
import "./eventBanner.css";
import Carousel from 'react-bootstrap/Carousel';
import Btn from "../../components/Button/Button";
import { ColourSwitch} from "../../components/colourswitch/colourswitch";
import { getParsedDate } from "../../helpers";

export const EventBanner = (props) => {
    const nextIcon = <span className={`gcicon-right-open`}></span>
    const prevIcon = <span className={`gcicon-left-open`}></span>
    const [displayItems, setDisplayItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchEvents = async () => {
        try {
            setIsLoading(true);
            let data = await populateEventsData();
            //setDisplayItems(data.eventItems.slice(0, 2));
            //Filter only future events
            let today = new Date().toISOString();
            setDisplayItems(data.eventItems.filter((f) => f.eventDate > today ));
        } catch (error) {
            setError({ message: "Uh oh, looks like something went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    if (error) {
        return <Error error={error} id="events-error" page="home-page" />;
    }

    return (
        <>
            {
                isLoading ? <Loader page="Home Events" /> :

                    <Carousel indicators={false} controls={true} pause='hover' variant="dark" nextIcon={nextIcon} prevIcon={prevIcon}>

                        {
                            displayItems.map((event, idx) => (

                                <Carousel.Item interval={5000} className="carousel-item" key={idx}>
                                    <div className={`${Styles.carouselWrapper} ${props.eventBannerBgColour}`}>
                                        <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${event.bannerImage})` }} className="d-block w-100 carouselImageContainer" >
                                        </div>
                                        <div className="" style={{}}>
                                            <Carousel.Caption>
                                                <p><span className={`${Styles.sectorMarker} ${ColourSwitch(event.category)}`}>|</span> <span className="sectorName">{event.category}</span></p>
                                                <p><span className="gcicon-calendar"> </span><span className="date">{getParsedDate(event.eventDate)}</span></p>
                                                <h2>{event.title}</h2>
                                                <Link as={Link} to={`/events/${event.urlSegment}`}>
                                                    <Btn text={"Find out more"} color="Black" />
                                                </Link>
                                            </Carousel.Caption>
                                        </div>
                                    </div>

                                </Carousel.Item>

                            ))
                        }
                    </Carousel>
            }
        </>
    )
}