
import "./colourswitch.css";

export const ColourBGSwitch = (category) => {
    let colour = '';
    switch (category) {
        case "Latest":
            colour = 'tealBg';
            break;
        case "Visitor economy":
            colour = 'yellowBg';
            break;
        case "Meetings, conferences, and events":
            colour = 'ltPurpleBg';
            break;
        case "Frontier sectors":
            colour = 'orangeBg';
            break;
        case "Net Zero":
            colour = 'ecoGreenBg';
            break;
        case "Place":
            colour = 'redBg';
            break;
        case "Internationalisation":
            colour = 'pinkBg';
            break;
        case "Corporate updates":
            colour = 'ltBlueBg';
            break;
        default:
            colour = 'midGreyBg';
            break;
    }
    return colour
}

export const ColourSwitch = (category) => {
    let colour = '';
    switch (category) {
        case "Latest":
            colour = 'teal';
            break;
        case "Visitor economy":
            colour = 'yellow';
            break;
        case "Meetings, conferences, and events":
            colour = 'ltPurple';
            break;
        case "Frontier sectors":
            colour = 'orange';
            break;
        case "Net Zero":
            colour = 'ecoGreen';
            break;
        case "Place":
            colour = 'red';
            break;
        case "Internationalisation":
            colour = 'pink';
            break;
        case "Corporate updates":
            colour = 'ltBlue';
            break;
        default:
            colour = 'midGrey';
            break;
    }
    return colour
}
