

import styles from "./Cookies.module.css"
import { useScript } from "../../utils/useScript";

export const Cookies = () => {
    const { Cookie } = useScript('https://consent.cookiebot.com/76f101b4-e895-4b3f-99ab-0a31296712ca/cd.js', 'CookieDeclaration', 'CookieDeclaration')
    return (
        <>
            <h1>Cookie policy</h1>
            <div className={styles.cookiesContainer}>
                <h2 className={styles.cookiesTitle}>Cookie Policy</h2>
                <div id="CookieDeclaration"></div>
                    {Cookie}
            </div>
        </>
    );
};