import React from "react";
import { useParams, Link } from "react-router-dom";

import { Social } from "../../components/social/social";
import { useIndividual } from "../../components/useIndividual/useIndivdual";
import { Loader } from "../../components/loader/loader";
import { Error } from "../../components/error/error";
import { ColourSwitch } from "../../components/colourswitch/colourswitch";
import { getParsedDate } from "../../helpers";

import Styles from "./eventsItem.module.css";
import Btn from "../../components/Button/Button";

import { Reveal } from "../../components/animations/reveal/reveal";
import { SlideLeft } from "../../components/animations/slideLeft/slideLeft";


export const EventsItem = () => {
    const { eventId } = useParams();
    const { data, isLoading, error } = useIndividual(eventId, "Events");

    const RenderHtml= (rawHTML) => {
        let output = rawHTML;
        let i = 0, x = 0;
        let _url = process.env.REACT_APP_MM_UMBRACO_API;
        const regex = /<img src="/g;
        let matches = output.matchAll(regex);
        for (const match of matches) {
            if (i > 0) {
                x = i * _url.length;
                output = [output.slice(0, match.index + match[0].length + x), _url, output.slice(match.index + match[0].length + x)].join('');
            } else {
                output = [output.slice(0, match.index + match[0].length), _url, output.slice(match.index + match[0].length)].join('');
            }
            i += 1;
        }
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: output } });
    };

    
    if (error) {
        return <Error error={error} id="event-error" page="event-page" />;
    }

    return (
        <>
            {
                isLoading ? <Loader page="Event" /> :
                    <div className={Styles.eventsPageContainer}>
                        <header className={`${Styles.eventsContainerTop}`}>
                            <div>
                                <p className={`${Styles.eventsContentMargin}`}>
                                    <Link to="/Events" state={{ from: "indivdualEvents" }} className={Styles.goBackDesktop}>
                                        <span className="gcicon-left-open"></span>Back to events
                                    </Link>
                                </p>
                            </div>
                            <div> <p className={`${Styles.eventsContentMargin}`}><span className={`${Styles.sectorMarker} ${ColourSwitch(data.category)}`}>|</span> <span className={`${Styles.sectorName}`}>{data.category}</span></p></div>
                            <Reveal>
                                <div style={{ backgroundImage: `url(data:image/png;charset=ISO-8859-1;base64,${data.bannerImage})` }} className={`${Styles.eventsHeroImage}`}></div>
                            </Reveal>
                        </header>

                        <div className={`${Styles.eventsItemContentContainer}`} >

                            <article className={`${Styles.eventsItemContentleft}`}>

                                <div className={`${Styles.eventDetailsContainer}`}>
                                    <Reveal>
                                        <p className={`${Styles.eventIconStyle}`}><i className="gcicon-calendar"> </i></p>
                                    </Reveal>
                                    <Reveal>
                                        <h2 className={`${Styles.eventsContentMargin}`}>{getParsedDate(data.eventDate)}</h2>
                                    </Reveal>
                                    <Reveal>
                                        <p className={`${Styles.eventsContentMargin}`}>{data.eventTime}</p>
                                    </Reveal>
                                    <Reveal>
                                        <p><span className={`${Styles.medium}`} style={{ display: 'flex', gap: '8px' }}><i className="gcicon-map-marker"></i>Location: <span className={`${Styles.normal}`}>{RenderHtml(data.eventAddress)}</span></span></p>
                                    </Reveal>
  
                                </div>

                                <div className={`${Styles.eventContentContainer}`}>
                                    <Reveal>
                                        <h1> {data.title}</h1>
                                    </Reveal>
                                    <Reveal>
                                        {RenderHtml(data.description)}
                                    </Reveal>
                                </div>
                                
                            </article>
                            <aside className={`${Styles.eventsAsideContainer}`}>
                                {data.share &&
                                    <div className={Styles.eventSocialContainer}>
                                    <SlideLeft>
                                        <Social socialtype="event" title={data.title} />
                                    </SlideLeft>
                                    </div>
                                }
                                {(data && data.callToAction && data.callToAction.name === '') ?
                                    <SlideLeft>
                                        <div className={`${Styles.formContainer}`}>

                                            <h2>Register for this event</h2>

                                            <form>
                                                <input type="text" id="name" name="name" label="Name" placeholder="Name"></input>
                                                <input type="text" id="email" name="email" label="Email" placeholder="Email"></input>
                                                <input type="text" id="tel" name="tel" label="Telephone" placeholder="Telephone"></input>
                                                <input className={`${Styles.submit}`} type="submit"></input>
                                            </form>

                                            <p className={`${Styles.disclaimerText} ${Styles.midGrey}`}>GDPR DISCLAIMER HERE</p>

                                        </div>
                                    </SlideLeft>
                                    :
                                    <SlideLeft>
                                        <div className={`${Styles.formContainer}`}>
                                            <h2>Register for this event</h2>
                                            <a href={data.callToAction.url} target="_blank" rel="noopener noreferrer">
                                                <Btn text={data.callToAction.name} color="Black" />
                                            </a>
                                        </div>
                                    </SlideLeft>
                                }
                            </aside>
                        </div>
                    </div>
            }
        </>
    );
};